<template>
  <div class="info">
    <van-cell center title="头像" is-link @click="doubleClearCache">
      <div style="position: relative;">
        <img v-if="info.avatarUrl" :src="info.avatarUrl" class="myImg" alt="" />
        <img v-else src="@/assets/img/noImg.png" class="myImg" alt="" />
        <!-- <input type="file" accept="image/*" @change="afterRead" id="inputId" /> -->
      </div>
    </van-cell>
    <van-field
      v-model="info.mobile"
      size="small"
      label="手机号码"
      placeholder="请输入昵称"
      input-align="right"
      @click="doubleClearCache"
      readonly
    />
    <van-field
      v-model="info.nickName"
      size="small"
      label="昵称"
      placeholder=""
      input-align="right"
      readonly
    />
    <van-field
      v-model="info.gender_name"
      size="small"
      label="性别"
      placeholder="请选择性别"
      input-align="right"
      readonly
      is-link
      @click="sexShow = true"
    />
    <van-field
      v-model="info.birthday"
      size="small"
      label="生日"
      placeholder="请选择生日"
      input-align="right"
      readonly
      is-link
      @click="birthdayShow = true"
    />
    <van-field
      v-model="info.address"
      size="small"
      label="地区"
      placeholder="请选择地区"
      input-align="right"
      readonly
      is-link
      @click="showArea = true"
    />

    <div class="confirmBtn" @click="submit">保存</div>
    <!-- 性别选择 -->
    <van-action-sheet
      v-model="sexShow"
      :actions="sexList"
      @select="onSexSelect"
    />
    <!-- 生日选择 -->
    <van-popup v-model="birthdayShow" position="bottom" round>
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择生日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="birthdayConfirm"
        @cancel="birthdayShow = false"
      />
    </van-popup>
    <!-- 地区选择 -->
    <van-popup v-model="showArea" position="bottom" round>
      <van-area
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getLsMyProfile, editProfile } from "@/api/my";
import { uploadFiles } from "@/api/index";
import { areaList } from "@vant/area-data";
import { formatTime } from "@/utils/index";
export default {
  data() {
    return {
      sexList: [
        { name: "男", id: "1" },
        { name: "女", id: "2" }
      ],
      sexShow: false,
      showArea: false,
      birthdayShow: false,
      minDate: new Date(1950, 0, 1),
      maxDate: new Date(2010, 11, 30),
      currentDate: "",
      areaList,
      info: "",
      clickCacheNumber:0,
    };
  },
  created() {
    this.getLsMyProfile();
  },
  methods: {
    // 老铁双击666
    doubleClearCache(){
      this.clickCacheNumber++;
      console.log(this.clickCacheNumber)
      if(this.clickCacheNumber == 4){
        this.$toast("清除缓存成功");
        localStorage.clear();
        sessionStorage.clear();
      }else if(this.clickCacheNumber == 3){
        this.$Vconsole.show()
      }
    },
    // 修改保存我的信息
    submit() {
      let data = {
        id: this.info.id,
        gender: this.info.gender,
        nickName: this.info.nickName,
        mobile: this.info.mobile,
        birthday: this.info.birthday,
        avatarUrl: this.info.avatarUrl,
        country: this.info.country,
        district: this.info.district,
        province: this.info.province,
        city: this.info.city
      };
      editProfile(data).then(res => {
        if (res.data) {
          this.$toast("保存成功");
          this.$router.go(-1);
        }
      });
    },
    // 性别选择
    onSexSelect(item) {
      this.info.gender = item.id;
      this.info.gender_name = item.name;
      this.sexShow = false;
    },
    // 选择图片回调
    afterRead(e) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      document.getElementById("inputId").value = "";
      uploadFiles(data).then(res => {
        if (res.data) {
          this.info.avatarUrl = res.data;
        }
      });
    },
    // 生日选择
    birthdayConfirm(value) {
      this.info.birthday = formatTime(value);
      this.birthdayShow = false;
    },
    // 确认选择地区
    onConfirm(values) {
      this.info.province = values[0].name;
      this.info.city = values[1].name;
      this.info.district = values[2].name;
      this.info.address = values[0].name + values[1].name + values[2].name;
      this.showArea = false;
    },
    // 获取我的信息
    getLsMyProfile() {
      getLsMyProfile().then(res => {
        this.info = res.data;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.info.birthday = this.info.birthday
          ? this.info.birthday.split("T")[0]
          : "";
        this.info.gender_name = { 1: "男", 2: "女" }[res.data.gender];
        if (res.data.country == "中国") {
          this.info.address = res.data.country + res.data.province + res.data.city + res.data.district;
        } else {
          this.info.address = res.data.country;
        }
        console.log(this.info.address)
        this.currentDate = new Date(this.info.birthday);
      });
    }
  }
};
</script>
<style>
.info .van-field__label {
  color: #323233 !important;
}
</style>
<style lang="less" scoped>
.info {
  #inputId {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
  }
  .myImg {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }
  .confirmBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    background: rgb(225, 6, 6);
    margin: 1rem 5%;
    text-align: center;
    padding: 0.5rem 0;
    color: white;
    font-size: 0.4rem;
    font-weight: bold;
    border-radius: 0.8rem;
  }
}
</style>
